import { TaskResponse } from "@9amhealth/openapi";
import styled from "@emotion/styled";
import type { FC, ReactNode } from "react";
import React, { useCallback, useEffect } from "react";
import {
  IconCheckmarkCompleted,
  IconClipboardApple,
  IconHeartWithHeartBeatLine,
  IconIllustrationArmMuscle,
  IconTaskClappingHands,
  IconTaskClipboardVial,
  IconTaskLabAtHome,
  IconTaskMoneyShield,
  IconTaskPapersheetPencil,
  IconTaskPersonWithStethoscope,
  IconTaskPharmacyMedicalCupSnakeBowlOfHygieia,
  IconTaskStethoscope,
  IconWeightScale
} from "src/constants/icons";
import { APP_BREAKPOINT } from "src/constants/layout";
import { OpenBrowser } from "src/hybrid/components/Browser";
import reportErrorSentry from "src/lib/reportErrorSentry";
import translate from "src/lib/translate";
import { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";
import type { TaskResponseKnown } from "src/state/TaskManagementBloc/TaskManagementBloc";
import TaskManagementBloc, {
  TaskKey
} from "src/state/TaskManagementBloc/TaskManagementBloc";
import { tracker, useBloc } from "src/state/state";
import type { TranslationKey } from "src/types/translationKey";
import Collection from "src/ui/components/StyledComponents/Collection";
import CollectionItemLink from "src/ui/components/StyledComponents/CollectionItemLink";
import Translate from "src/ui/components/Translate/Translate";
import {
  AppPopup,
  AppQueryPopupsController
} from "../AppQueryPopups/AppQueryPopupsBloc";
import AsyncContent from "../AsyncContent/AsyncContent";
import CollectionTitle from "../StyledComponents/CollectionTitle";

//#region Styled Components
const EmptyStateRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0 0 1.5rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: var(--color-cream-darker);

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    gap: 0.75rem;
    padding: 1.5rem;
    margin: 0 0 2.5rem;
  }
`;

const EmptyStateIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.75rem;
  aspect-ratio: 1;
  border: 1px solid var(--color-cream);
  border-radius: 0.5rem;
  box-sizing: border-box;

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    width: 3.375rem;
  }
`;
//#endregion

interface TaskListProps {
  programs: KnownProgram[];
}

const TaskIcons: Record<string, ReactNode> = {
  "clipboard-vial": <IconTaskClipboardVial />,
  stethoscope: <IconTaskStethoscope />,
  "doctor-card": <IconTaskPersonWithStethoscope />,
  "money-shield": <IconTaskMoneyShield />,
  form: <IconTaskPapersheetPencil />,
  "bowl-of-hygiena": <IconTaskPharmacyMedicalCupSnakeBowlOfHygieia />,
  "home-labs": <IconTaskLabAtHome />,
  arm: <IconIllustrationArmMuscle />,
  "clipboard-apple": <IconClipboardApple />,
  "heart-beat": <IconHeartWithHeartBeatLine />,
  "weight-scale": <IconWeightScale />
};

interface TaskConfig {
  title?: TranslationKey;
  translationKey?: TranslationKey;
  iconType?: keyof typeof TaskIcons;
  popup?: AppPopup;
  popupParams?: Record<string, string>;
}

const TASK_STATUSES_TO_SHOW = [
  TaskResponse.status.AVAILABLE,
  TaskResponse.status.IN_PROGRESS
];

const taskConfigMapBase: Record<TaskKey, TaskConfig | null> = {
  [TaskKey.ACTIVATE_HEALTH_SYNC]: {
    translationKey: "prompt.activate_health_sync",
    iconType: "heart-beat",
    popup: AppPopup.healthSyncSetup
  },
  [TaskKey.UPLOAD_LABS]: {
    translationKey: "prompt.upload_labs",
    iconType: "clipboard-vial",
    popup: AppPopup.uploadLabReport,
    popupParams: { showSkip: "true" }
  },
  [TaskKey.SELECT_PCP]: {
    translationKey: "prompt.select_pcp",
    iconType: "stethoscope",
    popup: AppPopup.profileSelectPCP,
    popupParams: { pcpShowSkip: "true" }
  },
  [TaskKey.PHARMACY_INSURANCE]: {
    translationKey: "prompt.add_pharmacy_insurance",
    iconType: "money-shield",
    popup: AppPopup.profileEnterPharmacyInsurance
  },
  [TaskKey.PREFERRED_PHARMACY]: {
    translationKey: "prompt.select_pharmacy",
    iconType: "bowl-of-hygiena",
    popup: AppPopup.profileSelectPharmacy
  },
  [TaskKey.CKECKIN_QUESTIONNAIRE]: {
    translationKey: "prompt.checkin_questionnaire",
    iconType: "form",
    popup: AppPopup.questionnaire,
    popupParams: {
      questionnaireId: "jreXfFTE",
      title: translate("prompt.checkin_questionnaire")
    }
  },
  [TaskKey.RECURRING_CKECKIN_QUESTIONNAIRE]: {
    translationKey: "prompt.checkin_questionnaire",
    iconType: "form",
    popup: AppPopup.questionnaire,
    popupParams: {
      questionnaireId: "hwvunLpQ",
      title: translate("prompt.checkin_questionnaire")
    }
  },
  [TaskKey.SCHEDULE_GETLABS]: {
    translationKey: "prompt.schedule_getlabs",
    iconType: "home-labs"
  },
  [TaskKey.AMAZON_WEIGHT_JOURNEY_WEEK1]: {
    translationKey: "prompt.startWeightJourney",
    iconType: "arm",
    popup: AppPopup.questionnaire,
    popupParams: {
      title: translate("prompt.startWeightJourney")
    }
  },
  [TaskKey.MEDICAL_INSURANCE]: null,
  [TaskKey.RXDIET_MEAL_PLAN_SETUP]: {
    translationKey: "rxDiet.button.createFirstMealPlan",
    iconType: "clipboard-apple",
    popup: AppPopup.rxDietNutritionProgram
  },
  [TaskKey.LOG_WEIGHT]: {
    translationKey: "prompt.log_weight",
    iconType: "weight-scale",
    popup: AppPopup.rpmDailyReadings
  }
};

type TaskConfigAdditionalData = {
  appointmentUrl?: string;
  schedulingUrl?: string;
  questionnaireRef?: {
    id: string;
    type: "TYPEFORM";
  };
  iconType?: keyof typeof TaskIcons;
  title?: string;
  popupType?: AppPopup;
  popup_url?: string;
};

type TaskWithConfig = TaskResponseKnown & {
  config: TaskConfig | null;
  additionalData?: TaskConfigAdditionalData;
};

function getTaskConfig(
  task: TaskResponseKnown,
  taskConfigMap: Record<TaskKey, TaskConfig | null>
): TaskConfig | undefined {
  const checksKeys = [
    `${task.program}.${task.group}.${task.slug}`,
    `${task.group}.${task.slug}`,
    task.slug
  ] as TaskKey[];
  let foundConfig: TaskConfig | undefined;
  for (const key of checksKeys) {
    const config = taskConfigMap[key];
    if (config) {
      foundConfig = config;
      break;
    }
  }
  return foundConfig;
}

const TaskList: FC<TaskListProps> = ({ programs }) => {
  const [taskConfigMap, setTaskConfigMap] =
    React.useState<Record<TaskKey, TaskConfig | null>>(taskConfigMapBase);
  const [, { loadProgramTasks, getTaskByStatus, getProgramTasks }] =
    useBloc(TaskManagementBloc);
  const [loading, setLoading] = React.useState(false);

  const allProgramTasks = programs
    .map((program) => getProgramTasks(program))
    .flat();
  const userHasTasks = allProgramTasks.length > 0;

  const getTranslationKey = useCallback(
    (task: TaskWithConfig): TranslationKey => {
      const foundConfig = getTaskConfig(task, taskConfigMap);
      if (!foundConfig || !foundConfig.translationKey) {
        reportErrorSentry(
          new Error(`No translation key for task ${task.slug}`)
        );
        return "unknown";
      }
      return foundConfig.translationKey;
    },
    [taskConfigMap]
  );

  useEffect(() => {
    if (allProgramTasks.length === 0) return;
    // patch config
    allProgramTasks.forEach((task) => {
      const config = getTaskConfig(task, taskConfigMap);
      if (config) {
        return;
      }

      const additionalData = task.additionalData as TaskConfigAdditionalData;
      const { title, iconType, popupType } = additionalData;
      // only add popup params if title and icon are set from API
      if (title && iconType) {
        const popupParams: Record<string, string | undefined> = { title };

        // add questionnaireId if popupType is questionnaire
        if (popupType === AppPopup.questionnaire) {
          popupParams.questionnaireId = additionalData.questionnaireRef?.id;
        }

        // add url if popupType is iframe
        if (popupType === AppPopup.iframe) {
          popupParams.url = additionalData.popup_url;
        }

        const fullKey = `${task.program}.${task.group}.${task.slug}` as TaskKey;
        taskConfigMap[fullKey] = {
          title,
          iconType,
          popup: popupType,
          popupParams
        } as TaskConfig;
        setTaskConfigMap({ ...taskConfigMap });
      }
    });
  }, [allProgramTasks]);

  const taskConfigShouldShow = (task: TaskResponseKnown): boolean => {
    const fullKey = `${task.program}.${task.group}.${task.slug}` as TaskKey;

    /**
     * only show meal plan setup if onboarding tasks are not visible (user has not completed onboarding)
     */
    if (fullKey.endsWith(TaskKey.RXDIET_MEAL_PLAN_SETUP)) {
      const onboardingTasksVisible = getTaskByStatus(
        TASK_STATUSES_TO_SHOW,
        KnownProgram.ONBOARDING
      );

      return onboardingTasksVisible.length === 0;
    }

    return true;
  };

  const openExternalUrl = async (url: string): Promise<void> => {
    await OpenBrowser(url, {
      useBaseUrl: false,
      presentationStyle: "popover"
    });
  };

  const loadTasks = async (showLoading = true) => {
    setLoading(showLoading);
    try {
      await Promise.all(programs.map((program) => loadProgramTasks(program)));
    } catch (error) {
      reportErrorSentry(error);
    } finally {
      setLoading(false);
    }
  };

  const handleWindowFocus = useCallback(() => {
    setLoading(userHasTasks);
    void Promise.all(programs.map((program) => void loadProgramTasks(program)));
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, []);

  useEffect(() => {
    void loadTasks();

    const onPopupClose = () => {
      // reload tasks when popup is closed
      void loadTasks(false);
    };

    //   add event listener when window is focused
    window.addEventListener("focus", handleWindowFocus as EventListener);

    // add close callback
    AppQueryPopupsController.callbacks.onClose.push(onPopupClose);

    return () => {
      // remove event listener when window is unfocused
      window.removeEventListener("focus", handleWindowFocus as EventListener);
      // remove popup close callback
      AppQueryPopupsController.callbacks.onClose =
        AppQueryPopupsController.callbacks.onClose.filter(
          (cb) => cb !== onPopupClose
        );
    };
  }, []);

  // only show tasks that are in the config map
  const tasks = programs
    .map((program) => {
      return getTaskByStatus(TASK_STATUSES_TO_SHOW, program);
    })
    .flat();

  const tasksOrdered = tasks.sort((a, b) => {
    // use the orfder from TaskConfigMap
    const aIndex = Object.keys(taskConfigMap).indexOf(a.slug);
    const bIndex = Object.keys(taskConfigMap).indexOf(b.slug);
    return aIndex - bIndex;
  });

  // if (
  //   healthSyncState.available &&
  //   healthSyncState.stepsAuthorized === "request"
  // ) {
  //   tasksOrdered.push({
  //     program: KnownProgram.ONBOARDING,
  //     group: "health-sync",
  //     slug: "activate-health-sync",
  //     status: TaskResponse.status.AVAILABLE,
  //     additionalData: {}
  //   });
  // }

  const withConfig: TaskWithConfig[] = tasksOrdered
    .map((task) => {
      const config = getTaskConfig(task, taskConfigMap);
      if (!config) {
        return null;
      }
      return {
        ...task,
        config
      } satisfies TaskWithConfig;
    })
    .filter((t) => {
      if (!t) return false;
      return taskConfigShouldShow(t);
    }) as TaskWithConfig[];

  const handleTaskClick = (task: TaskWithConfig) => {
    tracker.track("Task Item Clicked", {
      data: {
        "Task ID": task.id,
        "Task Slug": task.slug,
        "Task Group": task.group,
        "Task Program": task.program,
        "Task Title":
          task.config?.title ??
          translate(task.config?.translationKey ?? "unknown")
      }
    });

    if (task.slug === TaskKey.SCHEDULE_GETLABS) {
      setLoading(true);
      void openExternalUrl(
        (task.additionalData as { appointmentUrl: string }).appointmentUrl
      );
      return;
    }

    // handle with task additionalData (questionnaire)
    if (task.additionalData.questionnaireRef?.id) {
      setLoading(true);
      const config = getTaskConfig(task, taskConfigMap);
      const additionalParams = config?.popupParams ?? {};

      AppQueryPopupsController.openPopup(AppPopup.questionnaire, {
        additionalParameters: {
          questionnaireId: task.additionalData.questionnaireRef.id,
          ...additionalParams
        }
      });

      return;
    }

    // handle with hardcoded popups
    if (task.config?.popup) {
      setLoading(true);

      let customRxDietPopupParams = {};
      if (
        `${task.program}.${task.group}.${task.slug}` ===
        TaskKey.RXDIET_MEAL_PLAN_SETUP
      ) {
        customRxDietPopupParams = {
          showFirstStep: task.status === TaskResponse.status.AVAILABLE
        };
      }

      AppQueryPopupsController.openPopup(task.config.popup, {
        additionalParameters: {
          ...task.config.popupParams,
          ...customRxDietPopupParams
        }
      });
    }
  };

  const title = translate("program.tasks.title", {
    context: programs[0]
  });

  if (loading && withConfig.length === 0)
    return <AsyncContent check={[false]} height="16rem" />;

  if (withConfig.length === 0) {
    return (
      <>
        <CollectionTitle>{title}</CollectionTitle>

        <EmptyStateRow>
          <EmptyStateIconWrapper>
            <IconTaskClappingHands />
          </EmptyStateIconWrapper>
          <p className="strong color-c-80 m0">
            <Translate msg="program.tasks.completed" />
          </p>
        </EmptyStateRow>
      </>
    );
  }

  return (
    <>
      <CollectionTitle>{title}</CollectionTitle>

      <nine-info-section>
        <Collection slot="content">
          {withConfig.map((task) => (
            <CollectionItemLink
              key={`${task.program}/${task.group}/${task.slug}`}
              size="large"
              icon={
                TaskIcons[task.config?.iconType ?? ""] ?? (
                  <IconCheckmarkCompleted />
                )
              }
              onClick={() => {
                handleTaskClick(task);
              }}
              color={
                "linear-gradient(229deg, #ffdfb4 -17.69%, #fddee9 33.52%, #c1d7fb 81.64%)"
              }
              label={
                <p className="m0 strong">
                  {task.additionalData.title ?? (
                    <Translate
                      msg={getTranslationKey(task)}
                      variables={{ context: `${task.program}.${task.group}` }}
                    />
                  )}
                </p>
              }
            />
          ))}
        </Collection>
      </nine-info-section>
    </>
  );
};

export default TaskList;
