import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useEffect } from "react";
import { appStatusBarState } from "src/state/state";

const Wrap = styled.div`
  label: AppSubPage;
  position: relative;
  padding: 0 1.6rem 0;

  @media (min-width: 768px) {
    padding: 0 1.6rem 1rem;
  }
`;

const AppSubPage: FC<{
  children?: React.ReactNode;
}> = (props) => {
  useEffect(() => {
    appStatusBarState.subPageOpen();
    return () => {
      appStatusBarState.subPageClose();
    };
  }, []);

  return <Wrap>{props.children}</Wrap>;
};

export default AppSubPage;
