import styled from "@emotion/styled";
import React from "react";
import { APP_CONTENT_WIDTH } from "src/constants/layout";
import { getSupportedUserLanguage } from "src/lib/i18next";
import { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";
import Branding from "src/ui/components/Branding/Branding";
import DailyReadingsWidget from "src/ui/components/DailyReadings/DailyReadingsWidget";
import RxDietCard from "src/ui/components/RxDiet/RxDietCard";
import CollectionTitle from "src/ui/components/StyledComponents/CollectionTitle";
import TaskList from "src/ui/components/TaskList/TaskList";
import Translate from "src/ui/components/Translate/Translate";
import UserEducationalFeed from "src/ui/components/UserEducationalFeed/UserEducationalFeed";
import UserEducationalFeedOld from "src/ui/components/UserEducationalFeed/UserEducationalFeedOld";

const Wrap = styled.div`
  max-width: ${APP_CONTENT_WIDTH}px;
  margin: 0 1.4rem;

  --section-padding-x: 0;
`;

const HomePage = () => {
  const language = getSupportedUserLanguage();

  return (
    <Wrap>
      <DailyReadingsWidget />

      <Branding
        style={{
          marginBlockEnd: "1.6em"
        }}
      />

      <TaskList
        programs={[
          KnownProgram.ONBOARDING,
          KnownProgram.CARE,
          KnownProgram.MEAL_PLAN
        ]}
      />

      <RxDietCard />

      <CollectionTitle>
        <Translate msg="articles.title" />
      </CollectionTitle>
      {language === "es" ? <UserEducationalFeedOld /> : <UserEducationalFeed />}
    </Wrap>
  );
};

export default HomePage;
