import { useBloc } from "@blac/react";
import styled from "@emotion/styled";
import React, { useEffect } from "react";
import DailyReadingsBloc from "./DailyReadingsBloc";
import {
  AppPopup,
  useAppQueryPopup
} from "../AppQueryPopups/AppQueryPopupsBloc";
import translate from "src/lib/translate";
import { userState } from "src/state/state";
import { ProfileProgramMembership } from "src/state/UserCubit/UserCubit";
import { featureFlags } from "src/lib/featureFlags";

const Wrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0.2rem 0 1.3rem;
`;

const Button = styled.button`
  background: var(--Secondary-Dark-Cream, #f2efe7);
  border-radius: 2rem;
  padding: 0.5rem 0.65rem;
  font-size: 0.88rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.35rem;
  position: relative;

  * {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &[data-loading="false"] {
    * {
      opacity: 1;
    }
  }
`;

const Status = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(45%, -15%);
  display: flex;
  align-items: center;

  svg {
    width: 1.125rem;
    height: 1.125rem;
    display: block;
  }
`;

const TriggerArea = styled.div`
  position: absolute;
  inset: -1rem;
`;

const TinyScaleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    fill="none"
    viewBox="0 0 17 17"
  >
    <path
      stroke="#212121"
      stroke-linecap="round"
      d="m14.424 10.701-2.06 2.974a.5.5 0 0 1-.807.02l-1.11-1.437"
    />
    <path
      stroke="#212121"
      stroke-linecap="round"
      d="m14.55 9.032.26-6.212a.755.755 0 0 0-.77-.781l-10.477.15a.755.755 0 0 0-.749.802l.742 10.49a.756.756 0 0 0 .77.698l5.646-.08"
    />
    <path
      stroke="#212121"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M8.872 7.093c-.372.006-.738.101-1.08.28-.34.18-.65.442-.908.769L5.531 6.525c.43-.544.943-.979 1.51-1.278A3.964 3.964 0 0 1 8.84 4.78a3.964 3.964 0 0 1 1.81.415 4.78 4.78 0 0 1 1.548 1.235l-1.308 1.655a2.873 2.873 0 0 0-.93-.742 2.381 2.381 0 0 0-1.087-.25Zm0 0 .82-.992"
    />
  </svg>
);

const StatusIconSuccess = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    fill="none"
    viewBox="0 0 19 19"
  >
    <path
      fill="#6FC696"
      d="M9.654 17.37a7.99 7.99 0 0 1-2.602-.424 8.262 8.262 0 0 1-2.279-1.183 8.406 8.406 0 0 1-1.8-1.799 8.494 8.494 0 0 1-1.182-2.279 7.992 7.992 0 0 1-.423-2.602 8.1 8.1 0 0 1 .417-2.602 8.53 8.53 0 0 1 2.988-4.078A8.17 8.17 0 0 1 7.045 1.22 7.992 7.992 0 0 1 9.648.797c.909 0 1.776.141 2.602.423a8.15 8.15 0 0 1 2.279 1.183 8.463 8.463 0 0 1 2.994 4.078c.282.826.423 1.693.423 2.602 0 .91-.14 1.777-.423 2.602a8.397 8.397 0 0 1-2.994 4.078 8.168 8.168 0 0 1-2.273 1.183 7.99 7.99 0 0 1-2.602.423Zm-.89-4.327a.799.799 0 0 0 .392-.1c.124-.07.23-.17.317-.299l3.83-5.927c.053-.083.099-.17.136-.261a.705.705 0 0 0 .056-.268c0-.199-.077-.36-.23-.485a.76.76 0 0 0-.51-.193.626.626 0 0 0-.35.105.932.932 0 0 0-.28.3L8.74 11.286 7.126 9.295a.849.849 0 0 0-.286-.255.69.69 0 0 0-.324-.075c-.199 0-.37.07-.51.212a.699.699 0 0 0-.206.504c0 .1.017.195.05.286.037.087.091.175.162.262l2.01 2.421c.109.133.223.233.343.3.12.062.253.093.399.093Z"
    />
  </svg>
);

const DailyReadingsWidget = () => {
  const [state, { loadData, completedWeek, dataLoaded }] =
    useBloc(DailyReadingsBloc);
  const hasWeightProgram = userState.hasProgramMembership(
    ProfileProgramMembership.HEALTHY_WEIGHT_JOURNEY
  );
  const featureFlagEnabled = featureFlags.getFlag(
    "rpm_show_progress_floating_indicator"
  );
  const showWidget = featureFlagEnabled && hasWeightProgram;

  const [openPopup] = useAppQueryPopup(AppPopup.rpmDailyReadings);

  useEffect(() => {
    void loadData();
    void userState.getProfileAttributes();
  }, [loadData]);

  const label = translate("dailyReadings.aria.progress", {
    count: state.logsThisWeek
  });

  if (!showWidget) {
    return null;
  }

  return (
    <Wrap>
      <Button
        data-loading={state.loading && !dataLoaded}
        onClick={() => openPopup()}
        aria-label={label}
        title={label}
      >
        <TriggerArea />
        <TinyScaleIcon />
        <span>{state.logsThisWeek}/7</span>
        <Status>{completedWeek && <StatusIconSuccess />}</Status>
      </Button>
    </Wrap>
  );
};

export default DailyReadingsWidget;
