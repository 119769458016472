import { TaskResponse } from "@9amhealth/openapi";
import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { IconCaretToRightSmallArrow } from "src/constants/icons";
import translate from "src/lib/translate";
import ProgramBloc, {
  KnownProgram,
  KnownProgramInfo
} from "src/state/ProgramBloc/ProgramBloc";
import { useBloc } from "src/state/state";
import AsyncContent from "src/ui/components/AsyncContent/AsyncContent";
import ResponsiveImage from "src/ui/components/ResponsiveImage/ResponsiveImage";
import ProgramTaskItem from "src/ui/components/TaskItem/ProgramTaskItem";
import TextWithKeywords from "src/ui/components/TextWithKeywords/TextWithKeywords";
import Translate from "src/ui/components/Translate/Translate";

const BlueLink = styled(Link)`
  color: var(--color-sunrise-blue) !important;
  font-weight: bold;
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 0.3em;
`;

const ProgramPreviewCard: FC<{
  program?: KnownProgram;
  hideLink?: boolean;
  hideActions?: boolean;
}> = ({ program, hideActions, hideLink }) => {
  const [
    { userHasNoPrograms, autoProgramDetected },
    { getProgramContent, checkUserProfileProgramData }
  ] = useBloc(ProgramBloc, {});
  const knownProgram =
    program ?? autoProgramDetected ?? KnownProgram.GENERAL_ALL;
  const { title, description, slug, image } = getProgramContent(knownProgram);

  useEffect(() => {
    void checkUserProfileProgramData();
  }, []);

  const definedTitle = useMemo<string>(() => {
    if (!title) {
      return "";
    }

    if (!knownProgram) {
      return title;
    }

    const knownTitle = KnownProgramInfo[knownProgram]?.title;

    if (!knownTitle) {
      return title;
    }

    return translate(knownTitle);
  }, [knownProgram, title]);

  const definedDescription = useMemo<string>(() => {
    if (!description) {
      return "";
    }

    if (!knownProgram) {
      return description;
    }

    const knownDescription = KnownProgramInfo[knownProgram]?.description;

    if (!knownDescription) {
      return description;
    }

    return translate(knownDescription);
  }, [description, knownProgram]);

  if (userHasNoPrograms) {
    return null;
  }

  return (
    <div>
      <nine-info-section style={{ margin: "0.8em 0", width: "100%" }}>
        <nine-info-container slot="content">
          <nine-spacer s="md"></nine-spacer>
          <AsyncContent check={[image]} height="14rem">
            <ResponsiveImage alt={definedTitle} image={image} />
          </AsyncContent>

          <nine-spacer s="md"></nine-spacer>
          <AsyncContent check={[definedTitle]} width="60%" height="1.5rem">
            <h5 className="strong m0">{definedTitle}</h5>
          </AsyncContent>

          <nine-spacer s="xs"></nine-spacer>
          <AsyncContent check={[definedDescription]} height="3em">
            <TextWithKeywords text={definedDescription} />
          </AsyncContent>

          <nine-spacer s="md"></nine-spacer>

          {!hideLink && (
            <AsyncContent check={[slug]} width="160px" height="1.2rem">
              <BlueLink to={`/app/program/${slug}`}>
                <Translate msg="program.module.show" />{" "}
                <IconCaretToRightSmallArrow />
              </BlueLink>
            </AsyncContent>
          )}

          {!hideActions && (
            <ProgramTaskItem
              program={program}
              select={[
                { status: TaskResponse.status.AVAILABLE },
                { status: TaskResponse.status.IN_PROGRESS },
                { status: TaskResponse.status.LOCKED }
              ]}
            />
          )}
        </nine-info-container>
      </nine-info-section>
    </div>
  );
};

export default ProgramPreviewCard;
