import type { Bundle, Observation } from "fhir/r4";
import type { FC } from "react";
import React from "react";
import ObservationBundleBloc from "src/state/ObservationBundleBloc/ObservationBundleBloc";
import { useBloc } from "src/state/state";
import AsyncContent from "src/ui/components/AsyncContent/AsyncContent";
import Collection from "src/ui/components/StyledComponents/Collection";
import CollectionItemLink from "src/ui/components/StyledComponents/CollectionItemLink";
import CollectionTitle from "src/ui/components/StyledComponents/CollectionTitle";
import Translate from "src/ui/components/Translate/Translate";
import EmptyResultsState from "./StyledComponents/EmptyResultsState";

const LabResultGroupTable: FC<{
  observationBundles?: Bundle<Observation>[];
}> = (props) => {
  const [, { getBundleData }] = useBloc(ObservationBundleBloc, {
    create: () => new ObservationBundleBloc()
  });

  const observationBundlesPassed =
    typeof props.observationBundles !== "undefined";
  const { observationBundles = [] } = props;

  const noValues = observationBundlesPassed && !observationBundles.length;

  return (
    <>
      <CollectionTitle>
        <Translate msg="labResults" />
      </CollectionTitle>

      {noValues && (
        <EmptyResultsState>
          <p className="m0 strong color-c-80">
            <Translate msg="noResultsYet" variables={{ context: "labs" }} />
          </p>
        </EmptyResultsState>
      )}

      {!noValues && (
        <AsyncContent check={[observationBundlesPassed]} height="20rem">
          <Collection>
            {observationBundles.map((bundle) => {
              const { link, label, date } = getBundleData(bundle);

              return (
                <CollectionItemLink
                  key={bundle.identifier?.value}
                  to={link}
                  label={<p className="m0">{label}</p>}
                  status={<small>{date}</small>}
                />
              );
            })}
          </Collection>
        </AsyncContent>
      )}
    </>
  );
};

export default LabResultGroupTable;
