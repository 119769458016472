import styled from "@emotion/styled";
import type { FC, ReactElement, ReactNode } from "react";
import React, { useEffect, useMemo } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { InfoCircleIcon } from "src/constants/icons";
import { readMoreContent } from "src/constants/readMoreContent";
import reportErrorSentry from "src/lib/reportErrorSentry";
import sanityQuery from "src/lib/sanityQuery";
import SanityCubit from "src/state/SanityCubit/SanityCubit";
import { TrackEvent } from "src/state/Track/TrackCubit";
import { tracker, useBloc } from "src/state/state";
import type { TranslationKey } from "src/types/translationKey";
import InDialog from "src/ui/components/InDialog/InDialog";
import Link from "src/ui/components/Link/Link";
import SanityBlockContent, {
  type SanityContentBlock
} from "src/ui/components/SanityBlockContent/SanityBlockContent";
import SanityContent from "src/ui/components/SanityContent/SanityContent";
import Translate from "src/ui/components/Translate/Translate";

export const StyledIconButton = styled.button<{
  height?: number;
  width?: number;
  fontSize?: number;
}>`
  cursor: pointer;
  background: none;
  border: none;

  button {
    background: transparent;
    padding: 1px 0 0;
    width: ${({ width }): number => width ?? 27}px;
    font-weight: 700;
    height: ${({ height }): number => height ?? 27}px;
    border: 2.2px solid var(--color-error);
    color: var(--color-error);
    font-size: ${({ fontSize }): number => fontSize ?? 18}px;
    line-height: 1;
  }
`;

interface Props {
  name?: string;
  children?: ReactNode | undefined;
  slug: string;
  textButton?: boolean;
  textButtonDisplayText?: TranslationKey;
  className?: string;
  additionalFixedContent?: () => ReactElement;
  fetchFromCms?: boolean;
  hidden?: boolean;
  height?: number;
  width?: number;
  fontSize?: number;
}

interface CmsReadMoreItem {
  title: string;
  description?: string;
  slug: { current: string };
  language: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body: Record<string, any>[] | SanityContentBlock[];
  fullPackageInsertLink?: string;
}

const ReadMore: FC<Props> = ({
  name,
  slug,
  textButton,
  className,
  additionalFixedContent,
  fetchFromCms,
  textButtonDisplayText,
  hidden,
  height,
  width,
  fontSize
}): ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();
  const hash = location.hash.replace("#", "");
  const [query] = useSearchParams();
  const [, { fetchFirst, firstElement: sanityResult }] = useBloc(SanityCubit, {
    create: () => new SanityCubit<CmsReadMoreItem>()
  });

  const queryMatch = slug === query.get("readmore");

  useEffect(() => {
    if (fetchFromCms && queryMatch)
      void fetchFirst(sanityQuery.getBySlug(slug));
  }, [fetchFromCms, slug, queryMatch]);

  useEffect(() => {
    if (name === hash) {
      navigate(`?readmore=${slug}`);
    }
  }, [hash]);

  const localReadMoreContentData = useMemo(() => {
    if (fetchFromCms || !queryMatch) return;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (readMoreContent[slug] === undefined) {
      reportErrorSentry(
        `SLUG: "${slug}" does not have any content for ReadMore`
      );
      return readMoreContent.fallback;
    }
    return readMoreContent[slug];
  }, [slug, queryMatch]);

  const handleTogglePopupVisibility = (event?: React.MouseEvent): void => {
    event?.stopPropagation();
    const setTo = !queryMatch;

    if (setTo) {
      navigate(`?readmore=${slug}`);
      tracker.track(TrackEvent.readMore, {
        data: {
          title: fetchFromCms
            ? sanityResult?.title
            : localReadMoreContentData?.title,
          slug
        }
      });
    }
  };

  const popupProps = useMemo(() => {
    const independentProps = {
      onClose: handleTogglePopupVisibility,
      additionalFixedContent
    };

    if (!fetchFromCms) {
      return {
        ...independentProps,
        title: localReadMoreContentData?.title ?? "9amHealth",
        description: localReadMoreContentData?.description,
        mainContent: localReadMoreContentData?.paragraphs,
        fullPackageInsertLink: localReadMoreContentData?.fullPackageInsertLink
      };
    }

    const renderContent = (
      <SanityBlockContent
        blocks={sanityResult?.body as unknown as SanityContentBlock}
      />
    );

    return {
      ...independentProps,
      title: sanityResult?.title ?? "",
      description: sanityResult?.description,
      mainContent: renderContent,
      fullPackageInsertLink: sanityResult?.fullPackageInsertLink
    };
  }, [
    sanityResult,
    localReadMoreContentData,
    handleTogglePopupVisibility,
    additionalFixedContent
  ]);

  if (hidden)
    return (
      <InDialog title={popupProps.title} isOpen={queryMatch} returnUrl="?">
        <SanityContent {...popupProps} />
      </InDialog>
    );

  return (
    <>
      {textButton ? (
        <Link className={className} onClick={handleTogglePopupVisibility}>
          {textButtonDisplayText ? (
            <Translate msg={textButtonDisplayText} />
          ) : (
            <Translate msg={"read_more"} />
          )}
        </Link>
      ) : (
        <StyledIconButton
          className={className}
          onClick={handleTogglePopupVisibility}
          height={height}
          width={width}
          fontSize={fontSize}
        >
          <div className="icon-button ml1">
            <InfoCircleIcon />
          </div>
        </StyledIconButton>
      )}

      <InDialog title={popupProps.title} isOpen={queryMatch} returnUrl="?">
        <SanityContent {...popupProps} />
      </InDialog>
    </>
  );
};

export const StyledReadMore = styled(ReadMore)<{ absolute?: boolean }>`
  display: flex;
  justify-content: flex-end;
  margin: 0.1em 0 0 0.05em;
  ${({ absolute }): string =>
    absolute
      ? `
    position: absolute;
    bottom: 15px;
    right: 15px;
  `
      : ""}
`;

export default ReadMore;
