import type { DeviceItem } from "@9amhealth/openapi";
import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import { deviceTypeToNameMap } from "src/constants/devices";
import translate from "src/lib/translate";
import devicesBox from "src/ui/assets/images/medical-devices-box.jpg";
import { PurchaseItemTitle } from "src/ui/components/PurchaseItemTitle/PurchaseItemTitle";

interface Props {
  device: DeviceItem;
  image?: string;
  imageAlt?: string;
}

const Header = styled.h5`
  margin-top: var(--space-xs) !important;

  @media screen and (min-width: 960px) {
    margin-top: var(--space-sm) !important;
  }
`;

export const DeviceRowItem: FC<Props> = ({ device, image, imageAlt }) => {
  const defaultImage = {
    src: devicesBox,
    alt: translate("device.image_alt.device_box")
  };
  const text = deviceTypeToNameMap[device.type];
  if (text === null) {
    return null;
  }

  return (
    <nine-info-row
      variant="medication"
      imageSrc={image ?? defaultImage.src}
      imageAlt={imageAlt ?? defaultImage.alt}
    >
      <Header slot="title" className="strong">
        <PurchaseItemTitle text={text} isNewMed={false} isRampUp={false} />
      </Header>
    </nine-info-row>
  );
};
