import type { PurchaseItemResponse } from "@9amhealth/openapi";
import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useEffect, useMemo, useRef } from "react";
import {
  METADATA_DAILY_DOSAGE_TIMES,
  METADATA_NEW_MEDS_KEY,
  METADATA_RAMPING_UP_DOSAGE
} from "src/constants/misc";
import { checkItemType } from "src/lib/checkItemType";
import getPillsPerDay from "src/lib/getPillsPerDay";
import translate from "src/lib/translate";
import SubscriptionCubit from "src/state/SubscriptionCubit/SubscriptionCubit";
import {
  ItemType,
  SubscriptionInterval
} from "src/state/TreatmentPlanCubit/TreatmentPlanCubit";
import { useBloc } from "src/state/state";
import Price from "src/ui/components/Price/Price";
import { PurchaseItemTitle } from "src/ui/components/PurchaseItemTitle/PurchaseItemTitle";
import Translate from "src/ui/components/Translate/Translate";

const InlineWarning = styled.span`
  color: var(--color-error);
`;

interface Props {
  item: PurchaseItemResponse;
  duration?: SubscriptionInterval;
  onRemove?: (selectedItem: PurchaseItemResponse) => void;
  hideDetails?: boolean;
  image?: string;
  centerTitleVertical?: boolean;
  hideIfRemoved?: boolean;
  description?: string;
  inlineWarning?: string;
  showRefill?: boolean;
  sku?: string;
  hideRemove?: boolean;
  hidePrice?: boolean;
  rowPadding?: "" | "none";
}

export const MedicationRowItem: FC<Props> = ({
  item,
  hideDetails,
  onRemove,
  image,
  hideIfRemoved,
  description,
  inlineWarning,
  showRefill = false,
  hideRemove,
  hidePrice,
  rowPadding = ""
}) => {
  const medItem = useRef<HTMLDivElement>(null);
  const [{ hiddenItems }] = useBloc(SubscriptionCubit);
  const isHidden = hiddenItems?.includes(item.id);

  const isNewMed = item.metadata[METADATA_NEW_MEDS_KEY] === true;
  const dailyDosageTimes = item.metadata[METADATA_DAILY_DOSAGE_TIMES] as
    | number
    | undefined;

  const isRampUp = Boolean(
    Boolean(item.metadata[METADATA_RAMPING_UP_DOSAGE]) && dailyDosageTimes
  );

  const isPlaceholderMed = checkItemType(
    item.externalItemType,
    ItemType.PRELIMINARY_MEDICATION_SUGGESTION
  );

  const descriptionText = useMemo(() => {
    if (description) {
      return description;
    }

    if (isPlaceholderMed) {
      return translate("medication_description_placeholder_medication_dosage");
    }

    if (isRampUp)
      return translate(
        isNewMed ? "rampup_new_on_meds" : "rampup_existing_meds"
      );

    return getPillsPerDay(item);
  }, [item, isRampUp, isNewMed]);

  useEffect(() => {
    const node = medItem.current;
    if (hideIfRemoved && isHidden && node) {
      node.style.height = `${node.clientHeight}px`;

      requestAnimationFrame(() => {
        node.style.height = "0px";
        node.style.opacity = "0.5";
      });
    }
  }, [isHidden, hideIfRemoved]);

  const ndc = item.metadata["medication.ndc"] as string;

  return (
    <nine-info-row
      ref={medItem}
      variant="medication"
      imageSrc={image}
      imageAlt={translate("image.alt.pillBottle")}
      padding={rowPadding}
    >
      <h5 slot="title" className="m0 strong">
        <PurchaseItemTitle
          text={item.displayName}
          ndc={ndc}
          isNewMed={isNewMed}
          isRampUp={isRampUp}
        />
      </h5>

      {showRefill && (
        <nine-pill slot="pill" class="hide-below-switch">
          <Translate msg="autoRefill" />
        </nine-pill>
      )}

      {!hidePrice && (
        <p slot="price" className="m0 color-c-60">
          <Price amount={item.price} duration={SubscriptionInterval.monthly} />
        </p>
      )}

      {!hideRemove && onRemove && (
        <button
          slot="actions"
          className="flex items-center inline-button"
          onClick={(): void => onRemove(item)}
        >
          <div className="inline-button color-gray as-little strong hover-underline">
            <Translate msg="remove" />
          </div>
        </button>
      )}

      {!hideDetails && (
        <p slot="description" className="m0 color-c-80">
          {descriptionText}
          {inlineWarning && (
            <>
              <br />
              <InlineWarning>{inlineWarning}</InlineWarning>
            </>
          )}
        </p>
      )}
    </nine-info-row>
  );
};
