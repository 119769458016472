import type { FC, ReactNode } from "react";
import React from "react";

// Accessible emoji wrapper
const Emoji: FC<{ label: string; children?: ReactNode | undefined }> = ({
  label,
  children
}) => {
  return (
    <span
      className="emoji"
      role="img"
      aria-label={label}
      aria-hidden={label ? "false" : "true"}
    >
      {children}
    </span>
  );
};

export default Emoji;
